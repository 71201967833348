


































import {
  defineComponent,
  PropType,
  ref,
  useContext
} from '@nuxtjs/composition-api'
import { SfImage, SfHeading, SfLink } from '@storefront-ui/vue'
import Card from 'components/theme/UI/Card.vue';
import { useImage } from '@wemade-vsf/composables'

interface BlogPostInterface {
  created_at?: string | null;
  enabled?: number | null;
  image?: string | null;
  layout?: string | null;
  meta_description?: string | null;
  meta_title?: string | null;
  meta_keywords?: string | null;
  name?: string | null;
  post_content?: string | null;
  post_id?: number | null;
  short_description?: string | null;
  url_key?: string | null;
  topics?: {
    items: Array<{
      name: string,
      url_key: string
    }>
  }
}

export default defineComponent({
  name: 'BlogPost',
  props: {
    post: {
      type: Object as PropType<BlogPostInterface>,
      required: true
    },
    layout: {
      type: String,
      default: 'card'
    }
  },
  components: {
    Card,
    SfImage,
    SfHeading,
    SfLink
  },
  setup (props) {
    const { app, localeRoute } = useContext()
    const { getMagentoImage } = useImage()
    const imageSize = app.$wm.blog.imageSizes[props.layout]
    const blogPost = ref<BlogPostInterface>(props.post)
    
    const postUrl = localeRoute({ name: 'blogPost', params: { slug: blogPost.value.url_key } })

    return {
      blogPost,
      getMagentoImage,
      imageSize,
      postUrl
    }
  } 
})
